<template>
  <div>
    <div style="border-radius: 8px; border: 1px solid #E7E7E7; background: #FFFFFF">
      <div class="van-coupon__content" style="padding: 0;">
        <div class="van-coupon__head" style="max-width: 80px">
          <h3 class="van-coupon__amount" style="font-size: 16px">
            <van-image
              width="50"
              height="50"
              :src="product.image"
            />
          </h3>
          <p class="van-coupon__condition">{{ product.storeName }}</p>
        </div>
        <div class="van-coupon__body">
          <p class="van-coupon__name">{{ product.number }} 零</p>
          <div class="van-coupon__valid">
            <van-tag class="order-tag-margin-left" v-if="product.status == 0" type="primary">待定</van-tag>
            <van-tag class="order-tag-margin-left" v-if="product.status == 1" type="success">下单</van-tag>
            <van-tag class="order-tag-margin-left" v-if="product.status == 2" type="success">收货中</van-tag>
            <van-tag class="order-tag-margin-left" v-if="product.status == 3" type="success">完成</van-tag>
          </div>
          <div role="checkbox" class="van-checkbox van-coupon__corner" tabindex="0" aria-checked="false">
            <div class="van-checkbox__icon van-checkbox__icon--round">
              <van-button type="danger" size="small" style="margin-left: 3px;" @click="onClickDelete">删除</van-button>
            </div>
          </div>
        </div>
      </div>
      <van-grid :column-num="3">
        <van-grid-item v-for="subSItem in product.storeProductAttrValues" :key="subSItem.uuid">
          <div class="purchase-sub-item-info" @click="onSkuGridEdit(subSItem)">
            <span>{{ subSItem.sku }} <span class="purchase-sub-item-info-sl-span">每{{
                subSItem.zUnitName
              }}{{ subSItem.zUnitNum }}{{ subSItem.sUnitName }}</span></span>
            <div>
              <span>{{ subSItem.number }}{{ subSItem.sUnitName }}</span>
            </div>
            <span>{{ subSItem.price }}元</span>
          </div>
          <div>
            <van-tag class="order-tag-margin-left" v-if="subSItem.solution === '2'" type="success">补发</van-tag>

            <van-tag class="order-tag-margin-left"
                     v-if="subSItem.refundAmount !== undefined && subSItem.refundAmount !== null && subSItem.refundAmount > 0"
                     type="primary">金额
            </van-tag>
            <van-tag class="order-tag-margin-left"
                     v-if="subSItem.stockNum !== undefined && subSItem.stockNum !== null && subSItem.stockNum > 0"
                     type="danger">库存
            </van-tag>
          </div>

        </van-grid-item>
        <van-grid-item v-if="product.isExtraAttr" @click="popupExtraAttr">
          <van-icon name="plus" size="30"/>
        </van-grid-item>
      </van-grid>
    </div>
    <EditPopup
      ref="purchaseEditPopup"
      :pSkuObj="purchaseEditSkuObj"
      @editSku="purchaseEditSku"
      @deletedData="onDeletedData"
    ></EditPopup>
    <van-popup v-model="extraAttrPopup" :style="{ height: '45%' }" position="bottom">
      <van-form @submit="onExtraAttrSubmit">
        <van-field name="checkbox" label="规格">
          <template #input>
            <van-checkbox-group v-model="extraAttrChecks" direction="horizontal">
              <van-checkbox v-for="item in product.extraAttrList" :key="item.index" :name="item" shape="square"
                            style="margin-top: 3px">{{ item }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <div style="margin: 32px;">
          <van-button round block type="info" native-type="submit">确定</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {Checkbox, CheckboxGroup, CouponCell, CouponList, Field, Form, Grid, GridItem, Tag} from 'vant';
import EditPopup from "@/components/product/returnEditPopup.vue";
import * as purchaseService from "@/api/admin/purchase";
import * as returnService from "@/api/admin/return";

export default {
  components: {
    EditPopup,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Form.name]: Form
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      purchaseEditSkuObj: {},
      extraAttrPopup: false,
      extraAttrChecks: []
    };
  },
  watch: {
    product: {
      handler(newVal) {
        console.log('newVal', newVal)
      },
      immediate: true,
    }
  },
  created() {
  },
  methods: {
    onExtraAttrSubmit() {
      console.log(this.extraAttrChecks)
      const data = {
        purchasePlanUuid: this.product.purchasePlanUuid,
        productId: this.product.productId,
        extraAttrs: this.extraAttrChecks,
        storeName: this.product.storeName,
      }
      console.log('onExtraAttrSubmit', data)
      purchaseService.addSku(data).then(res => {
        if (res.status === 200) {
          this.extraAttrPopup = false
          this.$emit('onRefresh')
          this.$toast("下单成功")
        }
      })
    },
    popupExtraAttr() {
      this.extraAttrPopup = true
    },
    purchaseEditSku(data) {
      console.log('purchaseProductInfo 收到修改商品规格事件', this.product)
      console.log('purchaseProductInfo 收到修改商品规格事件 data', data)
      data.type = data.typeChecks.join(',')
      this.calculate4Product(data)
      this.$emit('infoEditSku', data)
      console.log('purchaseProductInfo 发送商品规格修改事件给父组件', data, this.product)
    },
    calculate4Product(data) {
      console.log('purchaseProductInfo calculate4Product', data)
      let number = 0
      data.amount = Number(data.number) * Number(data.price)
      this.product.storeProductAttrValues.forEach(item => {
        if (item.productId === data.productId && item.sku === data.sku) {
          item.solution = data.solution
          item.mark = data.mark
          item.stockNum = data.stockNum
          item.refundAmount = data.refundAmount
          item.typeChecks = data.typeChecks
          if (data.typeChecks !== undefined && data.typeChecks.length > 0) {
            item.type = data.typeChecks.join(',')
          }
          item.number = data.number
          item.price = data.price
        }
        number += Number(item.number)
      })
      this.product.number = number
    },
    onSkuGridEdit(data) {
      this.purchaseEditSkuObj = data
      this.$refs.purchaseEditPopup.isPopupShow = true
    },
    onClickDelete() {
      const data = {
        productId: this.product.productId,
        returnUuid: this.product.returnUuid
      }
      returnService.deleteP(data).then(res => {
        if (res.status === 200) {
          this.$emit('deletedData', this.product)
          this.$toast("删除成功")
        }
      })
    },
    onDeletedData(data) {
      console.log('onDeletedData', this.product)
      this.product.storeProductAttrValues = this.product.storeProductAttrValues.filter(t => t.productId === data.productId && t.sku !== data.sku)
    }
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.purchase-sub-item-info {
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.purchase-sub-item-info::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.purchase-sub-span {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 4px;
  color: #1989fa;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
}

.purchase-sub-item-info-sl-span {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
